import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { gridColumnWidths } from '@ui-v2/theme/layout';
import { ThemeGridColumnWidth } from '@ui-v2/types/layout';
import {
  ThemeDisplayProps,
  ThemeFlexboxProps,
  ThemeLayoutProps,
  ThemeMarginProps,
  ThemeOverflowProps,
  ThemeResponsiveProp,
  ThemeSpacing,
} from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import {
  mqMin,
  mqPrint,
  shouldForwardThemeProp,
} from '@ui-v2/utils/styleUtils';
import {
  buildLayoutProps,
  buildMargin,
  buildOverflowProps,
  buildDisplay,
} from '@ui-v2/utils/themePropBuilders';
import { GridGutter, GridGutterSize, gutterSizeMapper } from './GridRow';

export const convertThemeColumnProps = (
  keyInput: string | undefined | string[],
) => {
  if (!keyInput) {
    return keyInput;
  }
  const value = Array.isArray(keyInput) ? keyInput : [keyInput];

  return value.map((x) =>
    Object.keys(gridColumnWidths)
      .map((key) => key)
      .includes(x)
      ? `${(Number(x.split('/')[0]) / Number(x.split('/')[1])) * 100}%`
      : x,
  );
};

type Props = Pick<ThemeLayoutProps, 'width'> &
  ThemeDisplayProps & {
    children?: React.ReactNode;
    offset?: ThemeResponsiveProp<keyof ThemeGridColumnWidth>;
    order?: ThemeResponsiveProp<Pick<ThemeFlexboxProps, 'order'>>;
    overflow?: ThemeOverflowProps['overflow'];
    printWidth?: CSSProperties['width'];
    spacing?: GridGutterSize;
  };

const StyledGridColumn = styled('div', {
  shouldForwardProp: shouldForwardThemeProp,
})<
  Omit<Props, 'spacing'> &
    ThemeMarginProps & {
      gutter: GridGutter;
    }
>(({ gutter, printWidth, theme, ...props }) => [
  css`
    padding: 0 ${gutter.mobile / 2}px;

    ${mqMin[768]} {
      padding: 0 ${gutter.tablet / 2}px;
    }

    ${mqMin[1280]} {
      padding: 0 ${gutter.desktop / 2}px;
    }

    ${mqPrint} {
      display: grid;
      width: ${printWidth ?? '100%'};
      break-inside: avoid;
    }
  `,
  css(
    buildResponsiveValues({
      ...buildOverflowProps(props),
      ...buildMargin(props),
      ...buildLayoutProps(props, theme),
      ...buildDisplay(props),
    }),
  ),
]);

const GridColumn = ({
  offset,
  spacing = 'standard',
  width = '100%',
  ...props
}: Props) => (
  <StyledGridColumn
    gutter={gutterSizeMapper[spacing]}
    ml={convertThemeColumnProps(offset) as ThemeResponsiveProp<ThemeSpacing>}
    width={width}
    {...props}
  />
);

export default GridColumn;
