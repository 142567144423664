import { CSSProperties } from 'react';
import { ImageFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';
import { getImageSizes } from '../../../utils/getImageSizes';
import { DirectionProp } from '../../Grid/gridTypes';
import Image from '../../Image';
import {
  buildImageCardVariantImageProps,
  buildImageCardVariantProps,
} from './variants';

export type ImageCardVariant = 'stacked' | 'side-by-side';

export interface Props {
  description: Maybe<string>;
  direction?: DirectionProp;
  image: Maybe<ImageFragment>;
  objectFit?: CSSProperties['objectFit'];
  title: Maybe<string>;
  variant: ImageCardVariant;
}

const ImageCard = ({
  description,
  image,
  objectFit = 'contain',
  title,
  variant,
}: Props) => (
  <Box display="flex" gap={16} {...buildImageCardVariantProps(variant)}>
    {image && (
      <Box
        flex="0 0 auto"
        position="relative"
        {...buildImageCardVariantImageProps(variant)}
      >
        <Image
          alt={image.alt}
          fill
          objectFit={objectFit}
          sizes={getImageSizes({ Default: '200px' })}
          src={image.url}
        />
      </Box>
    )}
    <Box width={['9/12', '9/12', '9/12']}>
      <Box>
        {title && (
          <Text
            as="h3"
            className="image-card-title"
            colour="text.heading"
            mb={8}
            variant="heading-4"
          >
            {title}
          </Text>
        )}
        {description && (
          <Text as="div" className="image-card-description" variant="body-2">
            {description}
          </Text>
        )}
      </Box>
    </Box>
  </Box>
);

export default ImageCard;
