import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FrontPageSectionContentFragment } from '@codegen/cmsUtils';
import Tile from '@ui/components/Tile/Tile';
import { mqMin } from '@ui/styles/base';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import LandingPageSection from './LandingPageSection';

const LandingPageSectionWrapper = styled.div<{
  backgroundColor?: Maybe<string>;
  bottomSlantedToRightVariant?: Maybe<boolean>;
}>(({ backgroundColor, bottomSlantedToRightVariant }) => [
  backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `,
  bottomSlantedToRightVariant &&
    css`
      position: relative;
      width: min(100vw, 1440px);
      padding-top: 40px;
      padding-bottom: 60px;
      margin-left: calc((100% - min(100vw, 1440px)) / 2);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);

      > * {
        max-width: 1312px;
        margin: auto;
      }

      ${mqMin.ExtraSmall} {
        padding-bottom: 100px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
      }

      ${mqMin.Large} {
        padding-top: 80px;
        padding-bottom: 200px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 84%);
      }
    `,
]);

const LandingPageContent = ({
  content,
}: {
  content?: FrontPageSectionContentFragment[] | null;
}) => {
  if (!content || content.length === 0) {
    return null;
  }

  return (
    <Box overflowX="hidden" position="relative" zIndex="1">
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          gap={[48, 48, 56, 64]}
          minHeight={350}
          pb={[48, 48, 56, 64]}
          pt={[48, 48, 56, 64]}
        >
          {content.map(
            ({
              backgroundColor,
              backgroundVariant,
              id,
              isTile,
              sectionData,
            }) => {
              const bottomSlantedToRightVariant =
                backgroundVariant === 'bottom-slanted-to-right';

              const SectionContainer = isTile
                ? (props: { children: ReactNode; key: string }) => (
                    <Tile
                      {...props}
                      pb={[32, 32, 32, 48]}
                      pl={[24, 24, 32]}
                      pr={[24, 24, 32]}
                      pt={[24, 24, 32]}
                    />
                  )
                : (props: { key: string }) => <div {...props} />;

              return (
                <SectionContainer key={id}>
                  <LandingPageSectionWrapper
                    backgroundColor={backgroundColor}
                    bottomSlantedToRightVariant={bottomSlantedToRightVariant}
                  >
                    <LandingPageSection
                      content={sectionData}
                      isSectionTile={isTile}
                    />
                  </LandingPageSectionWrapper>
                </SectionContainer>
              );
            },
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPageContent;
