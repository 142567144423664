import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VendorDisplayFragment } from '@codegen/cmsUtils';
import Image from '@ui/components/Image';
import Link from '@ui/components/Link/Link';
import { mqMin } from '@ui/styles/base';
import Box from '@ui-v2/core/Box/Box';

interface Props {
  data: VendorDisplayFragment;
}
const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'restrictWidth',
})<{
  restrictWidth?: Maybe<boolean>;
}>(({ restrictWidth }) => [
  restrictWidth &&
    css`
      width: auto;
      max-width: 140px;
      height: 62px;
      ${mqMin.ExtraExtraSmall} {
        max-width: 130px;
      }
      ${mqMin.Medium} {
        max-width: 120px;
      }
    `,
]);

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const VendorDisplayWrapper = ({ data }: Props) => {
  const useFullContainerWidth = data.alignment === 'useFullWidth';

  return (
    <div>
      <Box
        border={data.useBorder ? 'default' : 'none'}
        columnGap={[24, 24, 24]}
        css={{
          borderRadius: data.borderRadius ?? 4,
        }}
        display="flex"
        flexWrap="wrap"
        justifyContent={
          useFullContainerWidth ? 'space-between' : 'space-around'
        }
        p={data.useBorder ? 16 : 0}
        rowGap={[24, 24, 24, 24, 32]}
      >
        {data.vendorDisplayInfo.map(({ link, logoHeight, vendor }) => {
          if (!vendor.logo.url) {
            return null;
          }

          if (link) {
            return (
              <StyledLink
                href={link.href}
                key={vendor.name}
                target={link.target ?? undefined}
              >
                <StyledImage
                  alt={vendor.iata}
                  height={logoHeight ?? 30}
                  objectFit="contain"
                  restrictWidth={useFullContainerWidth}
                  src={vendor.logo.url}
                  width={140}
                />
              </StyledLink>
            );
          }

          return (
            <StyledImage
              alt={vendor.iata}
              height={logoHeight ?? 30}
              key={vendor.name}
              objectFit="contain"
              restrictWidth={useFullContainerWidth}
              src={vendor.logo.url}
              width={140}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default VendorDisplayWrapper;
